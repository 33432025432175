<template>
  <div class="profile">
    <div class="container" v-if="data.order.id">
      <div class="profile__title">
        <h3><router-link :to="{ name: 'OrderListAdmin' }">{{$t('order.management')}}</router-link> / <span>{{$t('order.details')}}</span></h3>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-20">{{$t('order.view_order')}}</div>

        <div class="title-gray mg-b-5">{{$t('order.status')}}</div>
        <div class="text-dark">{{data.status === 1 ? $t('order.pending') : (data.status === 2 ? $t('order.completed') : $t('order.refunded'))}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.date')}}</div>
        <div class="text-dark">{{$filters.formatDate(data.order.created_at)}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.timestamp')}}</div>
        <div class="text-dark">{{$filters.formatTimestamp(data.order.created_at)}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.number')}}</div>
        <div class="text-dark">{{data.order.system_id}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.category')}}</div>
        <div class="text-dark">{{data.product_snapshot.store.service.name}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.type')}}</div>
        <div class="text-dark">{{$t(productType[data.product_snapshot.type])}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.type_c')}}</div>
        <div class="text-dark">{{$t(productType[data.product_snapshot.type])}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.product_snapshot')}}</div>
        <div class="text-dark">{{data.product_snapshot.name}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.product_c')}}</div>
        <div class="text-dark">{{data.product_snapshot.name_c}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.description')}}</div>
        <div class="text-dark">{{data.product_snapshot.description}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.description_c')}}</div>
        <div class="text-dark">{{data.product_snapshot.description_c}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.price')}}</div>
        <div class="text-dark">{{$filters.formatPrice(data.product_snapshot.original_price)}}</div>

        <div class="mg-t-20 mg-b-20">
          <img :src="data.product_snapshot.avatar" class="uploaded-img"/>
        </div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.terms')}}</div>
        <div class="flex align-center justify-start pointer">
          <a target="_blank" :href="data.product_snapshot.terms" class="flex align-center justify-start pointer" v-if="data.product_snapshot.terms">
            <img src="/images/icons/folder.png"/>
            <span class="red mg-l-10 underline">{{$t('order.view')}}</span>
          </a>
        </div>
      </div>

      <div class="box mg-b-10">

        <div class="flex mg-t-15 mg-b-15 align-start justify-start" v-if="data.product_snapshot.type !== 1 && data.product_snapshot.type !== 4">
          <div class="flex-33" v-if="data.product_snapshot.type === 2">
            <div class="title-gray mg-b-5">{{$t('order.promo_type')}}</div>
            <div class="text-dark">{{$t(promoTypes[data.product_snapshot.promotion_type])}}</div>
          </div>
          <div class="flex-33" v-if="data.product_snapshot.type === 3">
            <div class="title-gray mg-b-5">{{$t('order.negotiated_price')}}</div>
            <div class="text-dark">{{data.product_snapshot.negotiated_price ? $filters.formatPrice(data.product_snapshot.negotiated_price) : '-'}}</div>
          </div>
        </div>

        <div class="flex mg-t-15 mg-b-15 align-start justify-start" v-if="data.product_snapshot.type !== 1 && data.product_snapshot.type !== 4">
          <div class="flex-33" v-if="data.product_snapshot.type === 2">
            <div class="title-gray">{{$t('order.promo_price')}}</div>
            <div class="text-dark">{{data.product_snapshot.promotion_price ? $filters.formatPrice(data.product_snapshot.promotion_price) : '-'}}</div>
          </div>
          <div class="flex-33" v-if="data.product_snapshot.type === 3">
            <div class="title-gray">{{$t('order.points')}}</div>
            <div class="text-dark">{{data.product_snapshot.points || '-'}}</div>
          </div>
        </div>
        <div class="flex mg-t-15 mg-b-15 align-start justify-start" v-if="data.product_snapshot.type !== 1 && data.product_snapshot.type !== 4">
          <div class="flex-33">
            <div class="title-gray">{{$t('order.sales_start')}}</div>
            <div class="text-dark">{{$filters.formatDateOnly(data.product_snapshot.sale_starts_at)}}</div>
          </div>
          <div class="flex-33">
            <div class="title-gray">{{$t('order.sales_end')}}</div>
            <div class="text-dark">{{$filters.formatDateOnly(data.product_snapshot.sale_ends_at)}}</div>
          </div>
        </div>
        <div class="flex mg-t-15 mg-b-15 align-start justify-start" v-if="data.product_snapshot.type !== 1 && data.product_snapshot.type !== 4">
          <div class="flex-33">
            <div class="title-gray">{{$t('order.redemption_start')}}</div>
            <div class="text-dark">{{$filters.formatDateOnly(data.product_snapshot.redemption_starts_at)}}</div>
          </div>
          <div class="flex-33">
            <div class="title-gray">{{$t('order.redemption_end')}}</div>
            <div class="text-dark">{{$filters.formatDateOnly(data.product_snapshot.redemption_ends_at)}}</div>
          </div>
        </div>
        <div class="flex mg-t-15 mg-b-15 align-start justify-start">
          <div class="flex-33">
            <div class="title-gray">{{$t('order.quantity')}}</div>
            <div class="text-dark">{{data.quantity}}</div>
          </div>
          <div class="flex-33">
            <div class="title-gray">{{$t('order.amount')}}</div>
            <div class="text-dark">{{$filters.formatPrice(data.quantity * (data.product_snapshot.type === 3 ? data.product_snapshot.negotiated_price : data.unit_cost))}}</div>
          </div>
        </div>
        <div class="flex mg-t-15 mg-b-15 align-start justify-start">
          <div class="flex-33">
            <div class="title-gray">{{$t('order.user_name')}}</div>
            <div class="text-dark">{{data.user.first_name}} {{data.user.last_name}}</div>
          </div>
          <div class="flex-33">
            <div class="title-gray">{{$t('order.user_phone')}}</div>
            <div class="text-dark">{{data.user.phone}}</div>
          </div>
        </div>
        <div class="title-gray mg-t-15 mg-t-5">{{$t('order.user_email')}}</div>
        <div class="text-dark mg-b-15">{{data.user.email}}</div>
        <!-- <div class="title-gray mg-t-15 mg-t-5">{{$t('order.user_address')}}</div>
        <div class="text-dark mg-b-15">{{data.address.line_1}}, {{data.address.line_2}}, {{data.address.region.name}}, {{data.address.district.name}}</div> -->
        <div class="title-gray mg-t-20 mg-b-20" v-if="data.status !== 3">{{$t('order.confirm_txt')}}</div>
        <div class="flex align-center justify-start" v-if="data.status !== 3">
          <button class="btn btn--red" @click="openRefund">{{$t('global.refund')}}</button>
        </div>
        <div v-if="data.status === 3">
          <div class="title-gray mg-t-5 mg-b-5">{{$t('order.refund_notes')}}</div>
          <div class="text-dark">{{data.refund_reason ?? '-'}}</div>
        </div>
      </div>

      <div class="box mg-b-10">
<!--        <div class="title-gray mg-b-5">{{$t('order.merchant_notes')}}</div>-->
<!--        <div class="text-dark">{{data.merchant_comments || '-'}}</div>-->
        <div class="title-gray mg-b-15 mg-t-15">{{$t('order.admin_notes')}}</div>

        <div class="form-element">
          <textarea v-model="data.admin_comments" class="form-control"/>
        </div>

        <div class="flex align-center justify-end mg-t-20 mg-b-20">
          <button class="btn btn--green" @click="saveComments">{{$t('global.save')}}</button>
        </div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.merchant_edit_date')}}</div>
        <div class="text-dark">{{data.merchant_updated_at ? $filters.formatTimestamp(data.merchant_updated_at) : '-'}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.merchant_edit_email')}}</div>
        <div class="text-dark">{{data.merchant_updated_by ? data.merchant_updated_by.email : '-'}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.admin_edit_date')}}</div>
        <div class="text-dark">{{data.admin_updated_at ? $filters.formatTimestamp(data.admin_updated_at) : '-'}}</div>

        <div class="title-gray mg-t-15 mg-b-5">{{$t('order.admin_edit_email')}}</div>
        <div class="text-dark">{{data.admin_updated_by ? data.admin_updated_by.email : '-'}}</div>
      </div>
    </div>

    <Loading v-if="isLoading"/>
    <refund :transid="data.order.trans_id" :refund-id="data.id" :max="data.quantity * (data.product_snapshot.type === 3 ? data.product_snapshot.negotiated_price : data.unit_cost)" @onRefund="onRefund" ref="refundModal"/>
  </div>
</template>

<script>

import { Orders } from '@/api/api'
import Refund from '@/components/refund/refund'
import Loading from '@/components/loading/Loading'
import { promoTypes, productType } from '@/config/config'

export default {
  name: 'OrderDetails',
  components: {
    Loading,
    Refund
  },
  data () {
    return {
      orderId: 0,
      itemId: 0,
      data: {
        order: {},
        user: {},
        address: {
          region: {},
          district: {}
        },
        product_snapshot: {
          store: {
            service: {}
          }
        },
        refund_reason: ''
      },
      isLoading: true
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const ids = this.$route.params.id.split('-')

      if (ids.length === 2) {
        this.itemId = ids[1]
        this.orderId = ids[0]

        this.fetchOrder()
      }
    }
  },
  computed: {
    promoTypes () {
      return promoTypes
    },
    productType () {
      return productType
    }
  },
  methods: {
    openRefund () {
      this.$refs.refundModal.toggle()
    },
    formatTotalPrice (item) {
      return (item.quantity * item.unit_cost).toFixed(2)
    },
    async fetchOrder () {
      this.isLoading = true

      try {
        const resp = await Orders.getOne(this.orderId, this.itemId)

        const data = {
          ...resp.data.order_items[0]
        }

        data.user = {
          ...resp.data.user
        }

        data.address = {
          ...resp.data.address
        }

        delete resp.data.order_items
        delete resp.data.user
        delete resp.data.address

        data.order = {
          ...resp.data
        }

        this.data = { ...data }
      } catch (err) {
        this.$toast.error(this.$t('order.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    onRefund (data) {
      this.data.status = 3
      this.data.refund_amount = data.refund
      this.data.refund_reason = data.reason
      this.$refs.refundModal.toggle()
    },
    async saveComments () {
      this.isLoading = true

      try {
        await Orders.adminComment(this.data.id, this.data.admin_comments)

        this.$toast.success(this.$t('order.comments_saved'))
      } catch (err) {
        this.$toast.error(this.$t('order.comments_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
